// TO DO
// Change the hero background to be using gatsby image for that sweet lazy loading!

import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SEO from '../components/Layout/SEO';
import CategoryGrid from '../components/CategoryGrid';
import HomepageBody from '../components/HomepageBody';

export const QUERY = graphql`
  {
    sanityHomepage {
      body {
        _rawEn
      }
      hero {
        text
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;

export default function IndexPage({ data, location }) {
  const HeroStyle = styled.section`
    width: 100%;
    height: 550px;
    background-image: linear-gradient(
        0deg,
        rgba(45, 51, 74, 0.1),
        rgba(45, 51, 74, 0.1)
      ),
      url('${data.sanityHomepage.hero.image.asset.url}');
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 54px;
      font-weight: 700;
      color: var(--heading-color);
    }
  `;

  return (
    <>
      <SEO title="Welcome" location={location.href} />
      <HeroStyle>
        {data.sanityHomepage.hero.text ? (
          <h1>
            <em>{data.sanityHomepage.hero.text}</em>
          </h1>
        ) : (
          ''
        )}
      </HeroStyle>

      <CategoryGrid />

      <HomepageBody content={data.sanityHomepage.body._rawEn} />
    </>
  );
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};
