import React from 'react';
import PortableText from 'react-portable-text';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BodyStyle = styled.section`
  padding-bottom: 60px;
  h2 {
    margin-top: 0;
    color: var(--heading-color);
  }

  p {
    color: var(--body-color);
  }
`;

export default function HomepageBody({ content }) {
  return (
    <BodyStyle>
      <div className="container">
        <PortableText content={content} />
      </div>
    </BodyStyle>
  );
}

HomepageBody.propTypes = {
  content: PropTypes.any,
};
