import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const CATEGORY_QUERY = graphql`
  {
    categories: allSanityCategory(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        description
        slug {
          current
        }
        image {
          asset {
            url
          }
        }
      }
    }
  }
`;

const CategoryStyle = styled.section`
  width: 100vw;
  padding: 60px 0;
`;

const GridStyle = styled.div`
  width: 100%;
  text-align: center;

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 30px;
  justify-content: center;
`;

const Tile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background: var(--yellow);
  background-size: cover;
  background-position: center;
  color: white;
  transition: 0.2s;

  &:hover {
    transform: scale(1.02);
    opacity: 0.7;
  }

  h3 {
    color: white;
  }
`;

export default function CategoryGrid() {
  const { categories } = useStaticQuery(CATEGORY_QUERY);

  return (
    <CategoryStyle>
      <div className="container">
        <GridStyle>
          {categories.nodes.map((cat) => {
            const bg = `
              linear-gradient(
                0deg,
                rgba(45, 51, 74, 0.46),
                rgba(45, 51, 74, 0.46)
              ),
              url('${cat.image?.asset.url}')`;

            return (
              <Link to={`/${cat.slug.current}`} key={cat.id}>
                <Tile style={{ backgroundImage: bg }}>
                  <h3>
                    <em>{cat.title}</em>
                  </h3>
                </Tile>
              </Link>
            );
          })}
        </GridStyle>
      </div>
    </CategoryStyle>
  );
}

// make it responsive
